import algoliasearch from "algoliasearch"

// Job Collections
const jobClient = algoliasearch('CM2VEDLMUC', '494dd24ca283842706423715235cd631')
export const jobIndex = jobClient.initIndex('adoptactoJob');

// JobFairIndex Collections
const jobFairClient = algoliasearch('CM2VEDLMUC', '3582f6536a83cba864b128b7208d0fab')
// export const jobFairIndex = jobFairClient.initIndex('prod_lapiezaFairCompanies');
export const jobFairIndex = jobFairClient.initIndex('adoptactoJob');

//Company Collections
const companyClient = algoliasearch('CM2VEDLMUC', '3af051163f276889c2e6063be6cd3c3a');
// export const companyIndex = companyClient.initIndex('companyIndex');
export const companyJobFairIndex = companyClient.initIndex('adoptactpCompanies');

//Company Collections
const companyJobFair = algoliasearch('CM2VEDLMUC', '20d418990ab9a3892eb1b9f4af37d0ff');
// export const companyJobFairIndex = companyJobFair.initIndex('JobFair');

// Job Collection Volumen
const jobClientVol = algoliasearch('CM2VEDLMUC', '842f3eddee38f1be0639cf62ff380ce5');
export const jobVolIndex = jobClientVol.initIndex('JobsIndexVol');