import React from "react";
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import {palette} from "../../../style/pallette";
import Backdrop from "@material-ui/core/Backdrop";
import history from "../../../router/history";
import {destino} from '../../../system'

const ErrorRegister = (props) => {

    const {
        className,
        open,
        closed,
        message,
        goLogin,
        goFeria,
        title,
    } = props;

    function onClosed() {
        closed()
    }

    const goLoginHistory = () => {
        closed();
        history.push("/login")
    }

    const gotoFeria = () => {
        closed();
        window.location.href = destino;
    }

    const messageError = () => {
      let messageObject = {};
      switch (message) {
        case 'The password is invalid or the user does not have a password.':
          messageObject = {
            text: 'Contraseña incorrecta',
            forgot: true,
          };
          break;
        // eslint-disable-next-line no-fallthrough
        case 'There is no user record corresponding to this identifier. The user may have been deleted.':
          messageObject = {
            text: 'Correo electrónico no encontrado en LaPieza.io',
            forgot: false,
          };
          break;
        // eslint-disable-next-line no-fallthrough
        default:
          messageObject = {
            text: message,
            forgot: false,
          };
      }
      return messageObject;
    }

    return (
        <Modal
            key="Modal de eliminar candidatos"
            open={open}
            className={className}
            onClose={onClosed}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className="containerDelete">
                <header>
                    <h1>{title || 'Error al crear cuenta'}</h1>
                </header>
                <div className="bodyDelete">
                    {/* {!title && <label>Sucedio algo inesperado al importar la cuenta</label>} */}
                    <p style={{ fontSize: 20, color: '#232323' }}>{messageError().text}</p>
                    {messageError().forgot && <a
                      href="https://empresas.lapieza.io/forgot-pass"
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        fontSize: 16,
                        marginTop: 16,
                      }}
                    >¿Olvidaste tu contraseña?</a>}
                </div>
                <div className="footerDeleteOne">
                    {
                        goFeria ?
                            <button
                                onClick={gotoFeria}
                                style={{
                                    color: '#FFF',
                                    backgroundColor: palette.blue
                                }}
                            >Ir a la feria
                            </button>
                            : null
                    }
                    {
                        goLogin ?
                            <button
                                onClick={goLoginHistory}
                                style={{
                                    color: '#F7565D'
                                }}
                            >Iniciar sesión
                            </button>
                            : null
                    }
                    <button
                        onClick={closed}
                        style={{
                            color: '#ff405d'
                        }}
                    >Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    );

}

export default styled(ErrorRegister)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.16);
  .containerDelete {
    max-height: 60vh;
    background-color: ${palette.white};
    border-radius: 8px;
    max-width: 30vw;
    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }
    @media (max-width: 990px) {
      max-width: 80%;
    }
    @media (max-width: 600px) {
      max-width: 90%;
    }
  }
  header {
    height: 60px; 
    background-color: #1D1D38;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 16px;
      color: ${palette.white};
      text-align: center;
    }
  }
  .bodyDelete {
    padding: 30px;
    label {
      text-align: center;
      font-size: 14px;
    }
    p {
      text-align: center;
      font-size: 14px;
      color: #8C8CA2;
    }
  }
  .footerDelete {
    display: grid;
    padding: 0px 30px;
    padding-bottom: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    button {
      border: none;
      width: 100%;
      border-radius: 8px;
      background-color: ${palette.white};
      color: ${palette.black};
      font-size: 14px;
      padding-top: 5px;
      outline: none;
      padding-bottom: 5px;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
  }
  .footerDeleteOne {
    display: grid;
    padding: 0px 30px;
    padding-bottom: 30px;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    button {
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: ${palette.white};
      color: ${palette.black};
      font-size: 14px;
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
  }
`;

