import React, {useState} from 'react';
import Logo from "../../images/logo.png";
import styled from 'styled-components';
import {palette} from "../../style/pallette";
import leftImg from "../../images/image.jpg";
import Backdrop from '@material-ui/core/Backdrop';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse'
import Error from '../../components/Creator/Modal/ErrorRegister';
import database, {second__database, auth} from '../../firebase/firebase.js'
import axios from 'axios';
import {baseURL, destino} from '../../system'
import Checkbox from '@material-ui/core/Checkbox';
import {jobIndex} from '../../utils/algolia'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,.5) !important'
    },
    text: {
        color: '#fff',
        textAlign: 'center',
        fontSize: '1.5rem'
    },
    paper: {
        margin: theme.spacing(1),
    },
}));

const Creator = (props) => {

    const experiencia = [
        'Front-End Developer',
        'Mobile Developer',
        'Full-Stack Developer',
        'UX/UI Designer',
        'Devops Engineer',
        'Tech Support',
        'QA Engineer',
        'Data Science'
    ]

    const mainlenguajes = [
        'Javascript', 'Java', 'Python', 'Ruby', 'C/C++', 'C#', '.net', 'PHP', 'Objective-C', 'Swift', 'Kotlin', 'Ninguno', 'Otros'
    ]

    const secondlenguajes = [
        'Javascript', 'Java', 'Python', 'Ruby', 'C/C++', 'C#', '.net', 'PHP', 'Objective-C', 'Swift', 'Kotlin', 'Ninguno', 'Otros'
    ]

    const typeVacancy = [
        'Trainee', 'Junior', 'Mid', 'Senior'
    ]

    const share = [
        'Si', 'No'
    ]

    const typeSchool = [
        'Facebook Developer Circle',
        'Platzi',
        'BEDU',
        'Laboratoria',
        'Ironhack',
        'Le Wagon',
        'Hola Code',
        'Kodemia',
        'Otra'
    ]

    const [state, setState] = useState({
        email: 'jessica@nexu.mx',
        password: '',
        type: 'full',
        errors: null,
        terminos: false,
        register: false,
        name: '',
        estado: '',
        open: false,
        goLogin: false,
        error: false,
        message: '',
        phone: '',
        names: '',
        lastNames: '',
        phoneNumber: '',
        vacante: '',
        fileName: "",
        first: true,
        second: false,
        goFeria: false,
        linkedIn: '',
        experiencia: [],
        mainlenguajes: [],
        secondlenguajes: [],
        typeVacancy: [],
        share: true,
        typeSchool: [],
        vacantes: []
    })

    const classes = useStyles();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setState(prevState => ({...prevState, register: true, estado: 'Comenzando la migración', open: true}));
        try {
            await auth.signInWithEmailAndPassword("p.gonzalez@lapieza.io", "123456");
            console.log('Sesion iniciada');
        } catch (err) {
            setState(prevState => ({
                ...prevState,
                register: false,
                error: true,
                message: err.message,
                goFeria: false
            }))
            setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
            return;
        }
        const sendable = {
            email: state.email
        }
        const user = (await axios.request({
            baseURL,
            url: 'exist',
            data: sendable,
            method: 'POST'
        })).data
        if (!user.success) {
            setState(prevState => ({
                ...prevState,
                register: false,
                error: true,
                message: 'No tienes cuenta en LaPieza.io',
                goFeria: false
            }))
            setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
            return;
        }
        const claims = (await axios.request({
            // baseURL,
            url: 'https://us-central1-lapieza-production.cloudfunctions.net/claims', // Production
            // url: 'https://us-central1-pieza-development.cloudfunctions.net/claims',
            data: sendable,
            method: 'POST'
        })).data
        if (claims.success) {
            if (!claims.info.customClaims?.company) {
                setState(prevState => ({
                    ...prevState,
                    register: false,
                    error: true,
                    message: 'Tú cuenta es tipo usuario y no puede ser importada por este medio.',
                    goFeria: false
                }))
                setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
                return;
            }
        }
        console.log(claims?.info?.customClaims?.company)
        const exist = (await second__database.collection("companies").doc(claims.info.customClaims.company).get()).exists;
        if (exist) {
            setState(prevState => ({
                ...prevState,
                register: false,
                error: true,
                message: 'La empresa ya fue migrada',
                goFeria: true
            }))
            setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
            return;
        }
        const vacantes = (await database.collection("companies").doc(claims.info.customClaims.company).collection("jobs").where("Status", "==", true).get()).docs.map(e => e.data());
        setState(prevState => ({
            ...prevState,
            first: false,
            second: true,
            goFeria: false,
            vacantes
        }))
        setState(prevState => ({
            ...prevState,
            first: false,
            second: true
        }))

        setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
    };

    const handleSubmitsecond = async (event) => {
        event.preventDefault();
        const sendable = {
            email: state.email
        }
        const claims = (await axios.request({
            // baseURL,
            url: 'https://us-central1-lapieza-production.cloudfunctions.net/claims', // Production
            // url: 'https://us-central1-pieza-development.cloudfunctions.net/claims',
            data: sendable,
            method: 'POST'
        })).data
        setState(prevState => ({...prevState, register: true, estado: 'Comenzando la migración', open: true}))
        const exist = (await second__database.collection("companies").doc(claims.info.customClaims.company).get()).exists;
        if (exist) {
            setState(prevState => ({
                ...prevState,
                message: 'Ya te haz registrado a la feria',
                register: false,
                error: true,
                goFeria: true
            }))
            setState(prevState => ({...prevState, register: true, estado: 'Importar cuenta', open: false}))
            return;
        }
        const user = (await axios.request({
            baseURL,
            url: 'exist',
            data: sendable,
            method: 'POST'
        })).data
        console.log(user)
        const data = (await database.collection("companies").doc(claims.info.customClaims?.company).get()).data();
        const usuarios = (await database.collection("companies").doc(claims.info.customClaims?.company).collection("users").get()).docs.map(e => ({
            ...e.data(),
            id: e.id
        }));
        // const emails = usuarios.map(e => e.email);
        // await axios.request({
        //     baseURL,
        //     url: 'email-importar-empresa',
        //     data: {emails},
        //     method: 'POST'
        // })
        const payload = {
            ...data,
            localId: claims.info.customClaims?.company,
            isImported: true,
            whatFair: "#SinEtiquetas",
        };
        const register = (await axios.request({
            baseURL,
            url: 'registerEmpresas',
            data: {payload, usuarios},
            method: 'POST'
        })).data;
        console.log(register)
        if (register.success) {
            const Column = {
                '1Column': {name: 'Filtro Personal 1', index: 0},
                '2Column': {name: 'Filtro Personal 2', index: 1},
                '4Column': {name: 'Aceptado(s)', index: 3},
                '3Column': {name: 'Rechazados', index: 2},
            }

            await second__database.collection("companies").doc(claims.info.customClaims?.company).set(payload)

            await Promise.all(
                state.vacantes.filter(o => o.checked === true).map(
                    e => {
                        return Promise.resolve(
                            second__database.collection("companies").doc(claims.info.customClaims?.company).collection("jobs").doc(e.jobID).set(e)
                        )
                    }
                )
            )

            await Promise.all(
                state.vacantes.filter(o => o.checked === true).map(
                    e => {
                        const {
                            Status,
                            jobTitle,
                            jobPay,
                            seniority,
                            data,
                            latlang,
                            locationName,
                            redirectable,
                            redirectTo,
                            notify,
                            notifyWhom,
                            vacancyOwner,
                            jobID,
                            img,
                            mode,
                            contract,
                            companyName,
                            companyAbout,
                            logo,
                            createdAt,
                            questionare,
                            questions,
                            remoto,
                            hardskillsSelected,
                            category,
                            questionPersonal
                        } = e;
                        let publicData = {
                            Status,
                            jobTitle,
                            jobPay,
                            seniority,
                            data,
                            latlang,
                            locationName,
                            redirectable,
                            redirectTo,
                            notify,
                            notifyWhom,
                            vacancyOwner,
                            jobID,
                            objectID: jobID,
                            img,
                            mode,
                            contract,
                            companyName,
                            companyAbout,
                            logo,
                            createdAt,
                            questionare,
                            questions,
                            remoto,
                            hardskillsSelected,
                            category,
                            questionPersonal
                        }
                        publicData.objectID = e.jobID;
                        return Promise.resolve(
                            new Promise((resolve, reject) => {
                                jobIndex.saveObject(publicData, (err, content) => {
                                    if (err) {
                                        console.log(err)
                                        reject(err)
                                    }
                                    resolve(content)
                                })
                            })
                        )
                    }
                )
            )

            await Promise.all(
                state.vacantes.filter(o => o.checked === true).map(
                    e => {
                        return Promise.all(
                            Object.values(Column).map((data, inde) => {
                                Promise.resolve(second__database.collection(`companies/${claims.info.customClaims?.company}/jobs/${e.jobID}/vacation_column`).doc('ColumDefault' + inde).set(data));
                            })
                        )
                    }
                )
            )
        }
        try {
            auth.signOut()
            console.log('Sesion Cerrada')
            // window.location.href = destino + '/login';
        } catch (err) {
            console.error('Error al cerrar sesión')
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState({...state, [name]: value});
    };

    const handleSelectChange = (e, label) => {
        let temp = '';
        if (e) {
            const name = e.name;
            const value = e.value;
            if (!name) {
                setState({...state, [label]: e});
            } else {
                setState({...state, [name]: value});
            }
        } else {
            switch (label) {
                case 'experiencia':
                    temp = []
                    break
                case 'mainlenguajes':
                    temp = []
                    break
                case 'secondlenguajes':
                    temp = []
                    break
                case 'typeVacancy':
                    temp = []
                    break
                case 'share':
                    temp = ''
                    break
                case 'typeSchool':
                    temp = []
                    break
                default:
                    temp = ''
                    break
            }
            setState({...state, [label]: ''});
        }
    };

    const {
        className
    } = props;

    const resetError = () => setState(prevState => ({...prevState, error: !prevState.error}))

    return (
        <div className={className}>
            <Backdrop className={classes.backdrop} open={state.open}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Error
                closed={resetError}
                open={state.error}
                message={state.message}
                goLogin={state.goLogin}
                goFeria={state.goFeria}
            />
            <aside className='creator-left' style={{backgroundImage: `url(${leftImg})`}}/>
            <div className="creator-right">
                {/*Primera caja*/}
                <Collapse in={state.first}>
                    <Paper elevation={0} className={classes.paper}>
                        <img src={Logo} alt=""/>
                        <h3>Importa tu empresa de LaPieza.io a la feria</h3>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="email">Correo electrónico *</label>
                            <input
                                type="email"
                                name="email"
                                value={state.email}
                                required
                                onChange={handleInputChange}
                            />
                            <label htmlFor="email">Contraseña *</label>
                            <input
                                name="password"
                                type={state.showPassword ? 'text' : 'password'}
                                value={state.password}
                                required
                                onChange={handleInputChange}
                            />
                            <div className="creator-right_terminos">
                                <input type="checkbox" required value={state.terminos}
                                       onChange={(e) => setState({...state, terminos: e.target.checked})}/>
                                <a href="https://lapieza.io/legal/terminos-y-condiciones" target="_blank">Aceptar
                                    términos y
                                    condiciones</a>
                            </div>
                            <button>{state.register ? state.estado : 'Importar empresa'}</button>
                        </form>
                    </Paper>
                </Collapse>
                {/*Segunda caja*/}
                <Collapse in={state.second}>
                    <Paper elevation={0} className={classes.paper}>
                        <img src={Logo} alt=""/>
                        <h3>Un último paso</h3>
                        <form onSubmit={handleSubmitsecond}>
                            <label htmlFor="email">¿Qué vacantes migramos?</label>
                            <FormGroup>
                                {
                                    state.vacantes.map(e => (
                                        <FormControlLabel
                                            control={<Checkbox checked={e.checked || false} onChange={() => {
                                                const {vacantes} = state;
                                                vacantes.map(o => {
                                                    if (o.jobID === e.jobID) {
                                                        o.checked = !!!o.checked
                                                    }
                                                })
                                                setState(prevState => ({
                                                    ...prevState,
                                                    vacantes,
                                                }))
                                            }} name="gilad"/>}
                                            label={e.jobTitle}
                                        />
                                    ))
                                }
                            </FormGroup>
                            <button style={{marginTop: 10}}>Terminar mi registro</button>
                        </form>
                    </Paper>
                </Collapse>
            </div>
        </div>
    );
}

export default styled(Creator)`
  width: 100%;
  display: grid;
  grid-template-columns: 45% 55%;
  overflow-y: hidden;
  height: 100vh;
  .creator-left {
    width: 100%;
    background-size: cover;
    height: 100vh;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .creator-right {
    width: 100%;
    padding: 10vh 24px;
    display: block;
    justify-content: center;
    overflow-y: auto;
    img {
      width: 20%;
      margin: 0px auto;
    }
    h3 {
      font-size: 24px;
      text-align: center;
      margin: 0px;
      padding: 40px 0px;
    }
    form {
      width: 70%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      line-height: 2.4;
      a {
        color: ${palette.black};
      }
      input {
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #d3d5d8;
        padding: 10px 15px;
        &:focus {
          outline: none;
        }
      }
      label {
        font-size: 20px;
        padding: 10px 0px;
        text-align: left;
        line-height: 1.4;
        margin-top: 10px;
      }
      @media (max-width: 600px) {
        width: 90%;
      }
    }
    button {
      padding: 10px 30px;
      background-color: ${palette.blue};
      font-size: 16px;
      color: #FFF;
      font-weight: bold;
      border-radius: 4px;
      width: 100%;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
  }
  .creator-right_terminos {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    input {
      margin: 0px;
      width: fit-content;
      font-size: 16px;
    }
    a {
      margin: 0px;
      text-decoration: none;
      font-size: 16px;
      padding-left: 10px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
